import {EuiContextMenu} from '@elastic/eui'
import { TypeEuiPropsContextMenuPanelDescriptor } from 'Type'


type Props = {
    panels? :TypeEuiPropsContextMenuPanelDescriptor[]
    initialPanelId? :string | number
    size? :'s' | 'm'
}

const ComponentEuiContextMenu = ({ panels, initialPanelId, size } :Props) => {
    return <EuiContextMenu panels={panels} initialPanelId={initialPanelId} size={size} />
}

export default ComponentEuiContextMenu