import { EuiBreadcrumbs } from '@elastic/eui'
import { TypeEuiBreadcrumb } from 'Type'

type ResponsiveProps = {
    xs? :number
    s? :number
    m? :number
    l? :number
    xl? :number
}

type Props = {
    responsive? :ResponsiveProps | false
    truncate? :boolean
    max? :number
    breadcrumbs :TypeEuiBreadcrumb[]
    type?: 'application' | 'page'
}

const ComponentEuiBreadcrumbs = ({responsive, truncate, max, breadcrumbs, type} :Props) =>
    <EuiBreadcrumbs responsive={responsive} truncate={truncate} max={max} breadcrumbs={breadcrumbs} type={type}/>

export default ComponentEuiBreadcrumbs