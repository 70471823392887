import {ComponentDivWidth, ComponentEuiBadge, ComponentEuiButtonEmpty, ComponentEuiPopover, ComponentNavPageFilterTable } from 'Component'
import { HookControlSwitch, HookNavFilterPage } from 'Hook'
import { TypeControlSwitch, TypeNavControlFilterParam } from 'Type'

type Props = {
    fieldNameCamel? :string
    fieldNameMap? :{[fieldNameCamel :string] :string}
}

const ComponentNavPageFilterTablePopover = ({ fieldNameCamel, fieldNameMap } :Props) => {
    const { dataFilters } :TypeNavControlFilterParam = HookNavFilterPage(fieldNameCamel)
    const menuSwitch :TypeControlSwitch = HookControlSwitch();

    return <ComponentEuiPopover closePopover={menuSwitch.turnOff} isOpen={menuSwitch.value} panelPaddingSize={'none'}
                                button={<ComponentEuiButtonEmpty onClick={menuSwitch.toggle} size={'xs'} iconType={'arrowDown'} iconSide={'right'} color={'text'}>
                                    {`${dataFilters.length} ${(dataFilters.length === 1) ? 'filter' : 'filters'}`}
                                </ComponentEuiButtonEmpty>
    }>
        <ComponentDivWidth width={400}>
            <ComponentNavPageFilterTable fieldNameCamel={fieldNameCamel} fieldNameMap={fieldNameMap}/>
        </ComponentDivWidth>
    </ComponentEuiPopover>
}

export default ComponentNavPageFilterTablePopover