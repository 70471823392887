class ClassJsonFetch<T> {

    private readonly promise :Promise<T>
    private res :T | undefined

    constructor(url :string) {
        this.promise = fetch(url).then((resp :Response) => resp.json())
        this.promise.then((res :T) => this.res = res)
    }

    public getPromise() :Promise<T> {
        return this.promise
    }

    public getResult() :T | undefined {
        return this.res
    }
}

export default ClassJsonFetch;