import { EuiToolTip } from '@elastic/eui'
import {PropsWithChildren, ReactNode} from 'react'

type Props = {
    content?: ReactNode
    display? :'block' | 'inlineBlock'
    delay? :'regular' | 'long'
    title? :ReactNode
    id? :string
    position? :'left' | 'right' | 'top' | 'bottom'
}

const ComponentEuiToolTip = ({ content, display, delay, title, id, position, children } :PropsWithChildren<Props>) =>
    <EuiToolTip content={content} display={display} delay={delay} title={title} id={id} position={position}><>{ children }</></EuiToolTip>

export default ComponentEuiToolTip