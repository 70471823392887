import { HookSession } from 'Hook'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { TypeControlSession } from 'Type'
import { FORMAT_PAGE_URLS } from 'Format'


const PageHome = () => {

    const navigate = useNavigate()

    const control :TypeControlSession = HookSession()

    useEffect(() => {
        navigate((control.globalId) ? `/${control.globalId}` : FORMAT_PAGE_URLS.userLogin)
    }, []);

    return null
}

export default PageHome