
import { ComponentReactFlow } from 'Component'
import {HookNavPage, HookNavResourceNavAll} from 'Hook'
import { ModulePage } from 'Module'
import {
    TypeEntityInterface,
    TypeEntityInterfaceField,
    TypeHttpLoadable,
    TypeNav,
    TypeNavHttpControl,
    TypeReactFlowNode
} from 'Type'


const PageInterface = () => {

    const pageControl :TypeNavHttpControl<TypeEntityInterface> = HookNavPage<TypeEntityInterface>()
    const pageNav :TypeNav<TypeEntityInterface> | undefined = pageControl.res?.data

    const fieldControl :TypeNavHttpControl<TypeEntityInterfaceField> = HookNavResourceNavAll<TypeEntityInterfaceField>({key: 'field', nav: pageNav, sortDirection: 'asc'})

    const toLoad :TypeHttpLoadable[] = [
        {label: 'Interface', control: pageControl}
    ]

    const nodes :TypeReactFlowNode<Record<string, string>>[] = (fieldControl.res?.data?.data.page?.content ?? []).map((field :TypeEntityInterfaceField) => {
        return {
            id: field.nameCamel,
            position: {x: 0, y: 0},
            data: {label: field.name}
        }
    })

    return <ModulePage toLoad={toLoad} control={pageControl}>
        <ComponentReactFlow nodes={nodes} />
    </ModulePage>
}

export default PageInterface