import React, {PropsWithChildren} from 'react'
import {TypeNavControlAuth} from 'Type'
import {ComponentEuiBadge, ComponentEuiDescriptionList, ComponentEuiText, ComponentEuiToolTip} from 'Component'

type Props = {
    auth: TypeNavControlAuth
}

const ComponentNavAuthToolTip = ({ auth, children } :PropsWithChildren<Props>) => {
    return (auth.authorized) ? <>{ children }</> :
        <ComponentEuiToolTip title={ auth.message } content={<ComponentEuiText>
            <ComponentEuiDescriptionList compressed listItems={[
                {title: 'Session', description: <ComponentEuiBadge>{ auth.session }</ComponentEuiBadge>},
                {title: 'Required', description: <>{ auth.required?.map((required :string) => <ComponentEuiBadge key={required}>{ required }</ComponentEuiBadge>) }</>},
            ]}/>
        </ComponentEuiText>}>{children}</ComponentEuiToolTip>
}

export default ComponentNavAuthToolTip