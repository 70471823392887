import {
    HookModuleTransformHeaderErrorMap,
    HookNavResource,
    HookNavResourceNavAll
} from 'Hook'
import { ModulePageLoading, ModuleTransformBody } from 'Module'
import {
    TypeEntityTransform,
    TypeEntityTransformHeader,
    TypeEntityTransformLoad,
    TypeHttpLoadable,
    TypeModuleControlRefresh,
    TypeModuleTransformHeaderErrorControl,
    TypeNav,
    TypeNavControlResource,
    TypeNavHttpControl
} from 'Type'
import { UtilAsync } from 'Util'

type Props = {
    outputControl :TypeNavHttpControl<any>
    transformControl :TypeNavHttpControl<TypeEntityTransform>
    loadControl :TypeNavHttpControl<TypeEntityTransformLoad>
}

const PageRowBodyTransform = ({ outputControl, transformControl, loadControl } :Props) => {

    //transform
    const transformNav :TypeNav<any> | undefined = transformControl.res?.data

    //exclude
    const excludeResource :TypeNavControlResource | undefined = HookNavResource('exclude', transformNav)

    //header
    const headerControl :TypeNavHttpControl<TypeEntityTransformHeader> = HookNavResourceNavAll<TypeEntityTransformHeader>({key: 'header', nav: transformNav})
    const headerNav :TypeNav<TypeEntityTransformHeader> | undefined = headerControl.res?.data
    const headers :TypeEntityTransformHeader[] | undefined = headerNav?.data.page?.content

    const errorControl :TypeModuleTransformHeaderErrorControl = HookModuleTransformHeaderErrorMap({headers, transformNav})

    //output
    const outputNav :TypeNav<any> | undefined = outputControl.res?.data

    const refreshControl :TypeModuleControlRefresh = {
        headerRow: async () => {
            await transformControl.submit()
            await UtilAsync.awaitAll([headerControl.submit(), outputControl.submit(), loadControl.submit(), errorControl.refresh()])
        },
        header: async (destinationColumnNameCamel :string) => UtilAsync.awaitAll([headerControl.submit(), errorControl.refresh(), outputControl.submit(), loadControl.submit()]),
        error: async (destinationColumnNameCamel :string) => UtilAsync.awaitAll([errorControl.refresh(), loadControl.submit()]),
        exclude: async () => UtilAsync.awaitAll([outputControl.submit(), loadControl.submit()])
    }

    const loaded = headerNav && outputNav && transformNav && excludeResource

    const toLoad :TypeHttpLoadable[] = [
        {label: 'Transformation', control: transformControl},
        {label: 'Headers', control: headerControl},
        {label: 'Output', control: outputControl}
    ]

    const body = loaded && <ModuleTransformBody headerNav={headerNav} headerErrors={errorControl.errors} outputNav={outputNav} transformNav={transformNav} excludeResource={excludeResource} refreshControl={refreshControl}/>

    return <ModulePageLoading toLoad={toLoad}>{body}</ModulePageLoading>
}

export default PageRowBodyTransform