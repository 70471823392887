import { ComponentEuiDataGrid } from 'Component'
import { HookEuiDataGridColumnVisibility } from 'Hook'
import { ModuleTransformOutputTableCell,
    ModuleTransformOutputTablePopoverCell, ModuleTransformOutputTablePopoverHeader, ModuleTransformOutputTablePopoverRow } from 'Module'
import {ReactNode, useRef } from 'react'
import {
    TypeEntityTransform, TypeEntityTransformHeader,
    TypeEntityTransformHeaderError,
    TypeEuiPropsDataGridCellPopoverElement,
    TypeEuiPropsDataGridCellValueElement, TypeEuiPropsDataGridColumn, TypeEuiPropsDataGridColumnVisibility,
    TypeEuiPropsDataGridControlColumn, TypeEuiPropsDataGridRef,
    TypeModuleControlRefresh, TypeModuleTransformHeaderErrorMap, TypeNav, TypeNavControlResource } from 'Type'

type Props = {
    headerNav: TypeNav<TypeEntityTransformHeader>
    headerErrors :TypeModuleTransformHeaderErrorMap
    outputNav: TypeNav<any>
    transformNav :TypeNav<TypeEntityTransform>
    excludeResource :TypeNavControlResource
    refreshControl :TypeModuleControlRefresh
}

const mapToColumnFn = (refreshControl :TypeModuleControlRefresh) => (header :TypeEntityTransformHeader) :TypeEuiPropsDataGridColumn => {
    return {
        id: header.destinationColumnNameCamel,
        display: <ModuleTransformOutputTablePopoverHeader header={header} refreshControl={refreshControl}/>,
        actions: {showMoveLeft: false, showMoveRight: false, showHide: false}
    }
}

const ModuleTransformOutputTable = ({ headerNav, headerErrors, outputNav, transformNav, excludeResource, refreshControl } :Props) => {

    const dataGridRef = useRef<TypeEuiPropsDataGridRef | null>(null)

    const headers :TypeEntityTransformHeader[] = headerNav.data.page?.content ?? []
    const columns :TypeEuiPropsDataGridColumn[] = headers.map(mapToColumnFn(refreshControl))

    const data :any[] = (outputNav.data.list || outputNav.data.page?.content) ?? []

    const columnVisibility :TypeEuiPropsDataGridColumnVisibility = HookEuiDataGridColumnVisibility(columns)

    const getSheetRowIndexFromDataRowIndex = (dataRowIndex :number) :number | undefined => data[dataRowIndex]['']

    const getHeader = (columnId :string) :TypeEntityTransformHeader => {
        const opt :TypeEntityTransformHeader | undefined = headers.find((header :TypeEntityTransformHeader) :boolean => header.destinationColumnNameCamel === columnId)
        if (!opt) throw new Error('Column not found')
        return opt
    }

    const getValue = (rowIndex :number, columnId :string) :string => (data[rowIndex] && data[rowIndex][columnId] !== undefined) ? data[rowIndex][columnId] : ''

    const getError = (rowIndex :number, columnId :string) :TypeEntityTransformHeaderError | undefined => {
        const errors :TypeEntityTransformHeaderError[] | undefined = headerErrors[columnId]
        const sheetRowIndex :number | undefined = getSheetRowIndexFromDataRowIndex(rowIndex)
        if (!errors || !sheetRowIndex) return undefined
        return errors.find((e :TypeEntityTransformHeaderError) => e.sheetRowIndex === sheetRowIndex)
    }

    const errorRefreshFn = (destinationColumnNameCamel :string) => async () => {
        dataGridRef.current?.closeCellPopover()
        await refreshControl.error(destinationColumnNameCamel)
    }

    const renderCell = ({rowIndex, columnId } :TypeEuiPropsDataGridCellValueElement) =>
        <ModuleTransformOutputTableCell value={getValue(rowIndex, columnId)} error={getError(rowIndex, columnId)} header={getHeader(columnId)}/>

    const renderPopover = ({rowIndex, columnId } :TypeEuiPropsDataGridCellPopoverElement) =>
        <ModuleTransformOutputTablePopoverCell value={getValue(rowIndex, columnId)} error={getError(rowIndex, columnId)} onSuccess={errorRefreshFn(columnId)} />

    const leadingControlColumn :TypeEuiPropsDataGridControlColumn = {
        id: '',
        width: 60,
        headerCellRender: () => null,
        rowCellRender: (props: TypeEuiPropsDataGridCellValueElement) :ReactNode => {
            return <ModuleTransformOutputTablePopoverRow rowIndex={getValue(props.rowIndex, '')} refreshControl={refreshControl} transformNav={transformNav} excludeResource={excludeResource}/>
        }
    }

    if (data.length === 0 || columns.length === 0) return null

    return <ComponentEuiDataGrid columns={columns} rowCount={data.length} columnVisibility={columnVisibility}
                                 renderCellValue={renderCell} renderCellPopover={renderPopover} toolbarVisibility={false}
                                 leadingControlColumns={[leadingControlColumn]} forwardRef={dataGridRef}/>
}

export default ModuleTransformOutputTable