import { HookPublicEnv } from 'Hook'
import { TypeUrlParam, TypeHttpControlUrl } from 'Type'
import { UtilParam } from 'Util'

const HookHttpUrl = (path? :string, paramUpdates? :TypeUrlParam[]) :TypeHttpControlUrl => {
    const httpUrl :string | undefined = HookPublicEnv()?.httpUrl

    const buildUrl = (path? :string, paramUpdates? :TypeUrlParam[]) :string | undefined => {
        if (!path || !httpUrl) return undefined
        const url = UtilParam.url(httpUrl, path)
        if (paramUpdates) url.setAllParams(paramUpdates)
        return url.toString()
    }

    const url :string | undefined = buildUrl(path, paramUpdates)

    return { url, buildUrl }
}

export default HookHttpUrl