import {AxiosRequestHeaders} from "axios";
import {HookHttpUrl, HookNavHttpHeaders} from 'Hook'
import { useState } from 'react'
import { TypeHttpControlDeleteMulti, TypeHttpResponse } from 'Type'
import {UtilAsync, UtilHttpClient} from 'Util'

type Props = {
    paths: string[]
}

type ResMap<Res> = { [path :string] :TypeHttpResponse<Res> }

const HookHttpDeleteMulti = <Res>({ paths } :Props) :TypeHttpControlDeleteMulti<Res> => {

    const { buildUrl } = HookHttpUrl()
    const headers :AxiosRequestHeaders = HookNavHttpHeaders()

    const [resMap, setResMap] = useState<ResMap<Res>>({})

    const deleteOne = async (path :string) :Promise<void> => {
        const url: string | undefined = buildUrl(path)
        if (!url) return
        const res: TypeHttpResponse<Res> = (await UtilHttpClient.request<Res>(url, 'delete', headers).promise)
        setResMap((map :ResMap<Res>) => {
            map[path] = res
            return map
        })
    }

    const deleteAll = () :Promise<void> => {
        return UtilAsync.awaitAll(paths.map(deleteOne))
    }

    return {
        resMap,
        deleteAll
    }
}

export default HookHttpDeleteMulti