import {
    ComponentControlSwitch,
    ComponentDivMarginHorizontal,
    ComponentEuiFlexGroup,
    ComponentEuiFlexItem
} from 'Component'
import {HookControlSwitch, HookPageParamSimpleBoolean} from 'Hook'
import {ModuleTransformTitlePopoverExclude, ModuleTransformTitlePopoverHeader } from 'Module'
import {useEffect} from 'react'
import {
    TypeControlParamSimple,
    TypeControlSwitch,
    TypeEntityTransform,
    TypeModuleControlRefresh,
    TypeNav,
    TypeNavControlResource
} from 'Type'

type Props = {
    transformNav :TypeNav<TypeEntityTransform>
    excludeResource :TypeNavControlResource
    refreshControl :TypeModuleControlRefresh
}

const ModuleTransformTitlePopover = ({transformNav, excludeResource, refreshControl} :Props) => {

    const [paramErrorOnly, setParamErrorOnly] :TypeControlParamSimple<boolean> = HookPageParamSimpleBoolean('filter-errors-only', false)

    const errorSwitchControl :TypeControlSwitch = {
        value: !!paramErrorOnly,
        set: (on: boolean) => setParamErrorOnly(on),
        turnOn: () => setParamErrorOnly(true),
        turnOff: () =>  setParamErrorOnly(false),
        toggle: () =>  setParamErrorOnly(!paramErrorOnly),
    }

    return <ComponentEuiFlexGroup gutterSize={'s'} alignItems={'baseline'}>
        <ComponentEuiFlexItem>
            <ComponentControlSwitch control={errorSwitchControl} label={'Display errors only'}/>
        </ComponentEuiFlexItem>
        <ComponentEuiFlexItem>
            <ModuleTransformTitlePopoverHeader transformNav={transformNav} refreshControl={refreshControl}/>
            <ComponentDivMarginHorizontal margin={30}/>
        </ComponentEuiFlexItem>
        <ComponentEuiFlexItem>
            <ModuleTransformTitlePopoverExclude excludeResource={excludeResource} refreshControl={refreshControl}/>
        </ComponentEuiFlexItem>
    </ComponentEuiFlexGroup>
}

export default ModuleTransformTitlePopover