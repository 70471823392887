import {
    ComponentEuiButtonEmpty,
    ComponentEuiContextMenu,
    ComponentEuiIcon,
    ComponentEuiPopover, ComponentNavActionModalDeleteMulti
} from 'Component'
import { HookControlSwitch } from 'Hook'
import {TypeControlSwitch, TypeEntityRow} from 'Type'


type Props = {
    selectedRows :TypeEntityRow[]
    onComplete: () => void
}

const ModuleTableDataTableSelectedButton = ({ selectedRows, onComplete } :Props) => {

    const toggleMenu :TypeControlSwitch = HookControlSwitch()
    const toggleModal :TypeControlSwitch = HookControlSwitch()

    const onCompleteInner = () => {
        toggleModal.turnOff()
        toggleMenu.turnOff()
        onComplete()
    }

    return <><ComponentEuiPopover
        isOpen={toggleMenu.value}
        anchorPosition={'downCenter'}
        panelPaddingSize={'none'}
        closePopover={toggleMenu.turnOff}
        button={<ComponentEuiButtonEmpty size={'xs'} iconType={'arrowDown'} iconSide={'right'} color={'text'} onClick={toggleMenu.toggle}
            >{`${selectedRows.length} ${selectedRows.length === 1 ? 'item' : 'items'} selected`}</ComponentEuiButtonEmpty>
        }>
            <ComponentEuiContextMenu panels={[
                {
                    id :0,
                    title: 'Actions',
                    items: [{
                        name: `Delete ${selectedRows.length}`,
                        icon: <ComponentEuiIcon type={'trash'} color={'danger'}/>,
                        onClick: () => {
                            toggleMenu.turnOff()
                            toggleModal.turnOn()
                        },
                        disabled: (selectedRows.length === 0)
                    }]
                }
            ]}
            initialPanelId={0}/>
    </ComponentEuiPopover>
    { (toggleModal.value) ? <ComponentNavActionModalDeleteMulti paths={selectedRows.map((row :TypeEntityRow) => row.linkTo)}
                                    entityName={'Row'} onClose={toggleModal.turnOff} onComplete={onCompleteInner}/> : null }
    </>
}

export default ModuleTableDataTableSelectedButton