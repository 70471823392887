import {
    HookModuleRowDataProps, HookModuleTableDataProps, HookNavContextOptional,
    HookNavGet, HookNavPage, HookNavResourceNav } from 'Hook'
import { ModulePage } from 'Module'
import { PageRowBody, PageRowBodyChild, PageRowBodyExtract, PageRowBodyTransform, PageRowSidebar } from 'Page'
import {TypeEntityExtract, TypeEntityTransform,
    TypeEntityTransformDestination,
    TypeEntityTransformLoad, TypeHttpLoadable, TypeModuleTableDataProps, TypeNav, TypeNavHttpControl } from 'Type'

const PageRow = () => {

    //Page
    const pageControl :TypeNavHttpControl<any> = HookNavPage<any>()
    const pageNav :TypeNav<any> | undefined = pageControl.res?.data

    //Context
    const extractContext :TypeEntityExtract | undefined = HookNavContextOptional('extract', pageNav)
    const transformContext :TypeEntityTransform | undefined = HookNavContextOptional('transform', pageNav)

    //props
    const rowTableProps :TypeModuleTableDataProps = HookModuleRowDataProps(pageControl, 'version')
    const childTableProps :TypeModuleTableDataProps = HookModuleTableDataProps(pageControl, 'child')

    const toLoad :TypeHttpLoadable[] = [
        {label: 'Row', control: pageControl},
        {label: 'Columns', control: rowTableProps.columnControl},
    ]

    //Transform
    const transformControl :TypeNavHttpControl<TypeEntityTransform> = HookNavGet<TypeEntityTransform>({linkTo: transformContext})
    const transformNav :TypeNav<any> | undefined = transformControl.res?.data
    const destinationControl :TypeNavHttpControl<any> = HookNavResourceNav<TypeEntityTransformDestination>({key: 'destination', nav: transformNav})
    const destination :TypeEntityTransformDestination | undefined = destinationControl.res?.data?.data.entity
    const loadControl :TypeNavHttpControl<TypeEntityTransformLoad> = HookNavResourceNav<TypeEntityTransformLoad>({key: 'load', nav: transformNav})
    const loadNav :TypeNav<TypeEntityTransformLoad> | undefined = loadControl.res?.data

    const sidebar = <PageRowSidebar pageControl={pageControl} destination={destination} loadNav={loadNav}/>
    const body = <PageRowBody rowTableProps={rowTableProps}>
            { (transformContext) ? <PageRowBodyTransform outputControl={pageControl} transformControl={transformControl} loadControl={loadControl}/> :
                (extractContext) ? <PageRowBodyExtract pageControl={pageControl}/> :
                    <PageRowBodyChild pageControl={pageControl} childTableProps={childTableProps}/> }
    </PageRowBody>

    return <ModulePage control={pageControl} sidebarContent={sidebar} sidebarPaddingSize={'m'} toLoad={toLoad}
                       paginationControl={childTableProps.dataControl} socketControl={(extractContext) ? undefined : childTableProps.updatesControl}>{body}</ModulePage>
}

export default PageRow