import React, {PropsWithChildren} from 'react'
import { EuiProvider } from '@elastic/eui'

type Props = {
    colorMode: 'light' | 'dark' | 'inverse'
}

const ComponentEuiProvider = ({ colorMode, children }: PropsWithChildren<Props>) =>
    <EuiProvider colorMode={colorMode}>{children}</EuiProvider>

export default ComponentEuiProvider