import { ComponentEuiButtonIcon } from 'Component'
import { HookNavNavigateEntity } from 'Hook'
import { TypeEntityRow } from 'Type'

type Props = {
    row :TypeEntityRow
}

const ModuleTableColumnControlLink = ({ row } :Props) => {
    const navigate :() => void = HookNavNavigateEntity({entity: row})
    return <ComponentEuiButtonIcon iconType={'link'} size={'xs'} color={'primary'} onClick={navigate}/>
}

export default ModuleTableColumnControlLink

