import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import '@elastic/eui/dist/eui_theme_light.css'

import {PageApi, PageEmailConfirmationSuccess, PageHome,
    PageInterface, PageLogin, PageNavigator, PageOrganisation, PagePasswordNew,
    PagePasswordReset, PageRow, PageSignup, PageTable, PageTableRedirect, PageUser, PageVersion, PageWorkspace } from 'Page'
import {FORMAT_PAGE_URLS} from 'Format'
import {ComponentEuiProvider, ComponentNavPageRedirect } from 'Component'

const App = () => {
    return <ComponentEuiProvider colorMode={'light'}>
        <Router basename='/'>
            <Routes>
                <Route path={'/nav/*'} element={<PageNavigator/>}/>
                <Route path={'/'} element={<PageHome/>}/>
                <Route path={FORMAT_PAGE_URLS.userLogin} element={<PageLogin/>}/>
                <Route path={FORMAT_PAGE_URLS.userSignup} element={<PageSignup/>}/>
                <Route path={FORMAT_PAGE_URLS.userPasswordReset} element={<PagePasswordReset/>}/>
                <Route path={'/api/:apiId'} element={<ComponentNavPageRedirect navKey={'ws'}/>}/>
                <Route path={'/api/:apiId/ws'} element={<PageApi/>}/>
                <Route path={'/org/:orgId'} element={<ComponentNavPageRedirect navKey={'ws'}/>}/>
                <Route path={'/org/:orgId/ws'} element={<PageOrganisation/>}/>
                <Route path={'/user/:userId'} element={<ComponentNavPageRedirect navKey={'ws'}/>}/>
                <Route path={'/user/:userId/email-confirmation/:token'} element={<PageEmailConfirmationSuccess/>}/>
                <Route path={'/user/:userId/password-reset/:token'} element={<PagePasswordNew/>}/>
                <Route path={'/user/:userId/ws'} element={<PageUser/>}/>
                <Route path={'/:owner/:ownerId/ws/:ws'} element={<ComponentNavPageRedirect navKey={'table'}/>}/>
                <Route path={'/:owner/:ownerId/ws/:ws/table'} element={<PageWorkspace activeResourceKey={'table'}/>}/>
                <Route path={'/:owner/:ownerId/ws/:ws/iface'} element={<PageWorkspace activeResourceKey={'iface'}/>}/>
                <Route path={'/:owner/:ownerId/ws/:ws/iface/:ifaceId'} element={<PageInterface/>}/>
                <Route path={'/:owner/:ownerId/ws/:ws/responder'} element={<PageWorkspace activeResourceKey={'responder'}/>}/>
                <Route path={'/:owner/:ownerId/ws/:ws/table/:table'} element={<PageTableRedirect/>}/>
                <Route path={'/:owner/:ownerId/ws/:ws/table/:table/version/:version'} element={<PageVersion/>}/>
                <Route path={'/:owner/:ownerId/ws/:ws/table/:table/version/:version/row'} element={<PageTable/>}/>
                <Route path={'/:owner/:ownerId/ws/:ws/table/:table/version/:version/row/:rowId'} element={<PageRow/>}/>
                <Route path={'/:owner/:ownerId/ws/:ws/table/:table/version/:version/row/:rowId/child/:childId/row'} element={<PageRow/>}/>
                <Route path={'/:owner/:ownerId/ws/:ws/table/:table/version/:version/row/:rowId/cell/:cell/extract/:extract'} element={<PageRow/>}/>
                <Route path={'/:owner/:ownerId/ws/:ws/table/:table/version/:version/row/:rowId/cell/:cell/extract/:extract/sheet/:sheet'} element={<ComponentNavPageRedirect navKey={'data'}/>}/>
                <Route path={'/:owner/:ownerId/ws/:ws/table/:table/version/:version/row/:rowId/cell/:cell/extract/:extract/sheet/:sheet/data'} element={<PageRow/>}/>
                <Route path={'/:owner/:ownerId/ws/:ws/table/:table/version/:version/row/:rowId/cell/:cell/extract/:extract/sheet/:sheet/transform/:transform'} element={<ComponentNavPageRedirect navKey={'output'}/>}/>
                <Route path={'/:owner/:ownerId/ws/:ws/table/:table/version/:version/row/:rowId/cell/:cell/extract/:extract/sheet/:sheet/transform/:transform/output'} element={<PageRow/>}/>
            </Routes>
        </Router>
    </ComponentEuiProvider>
}

export default App
