import { HookPublicEnv, HookSession } from 'Hook'
import { TypeHttpControlUrl } from 'Type'
import { UtilParam } from 'Util'

const HookWebSocketUrl = (path? :string) :TypeHttpControlUrl => {
    const wsUrl :string | undefined = HookPublicEnv()?.wsUrl
    const { token } = HookSession()

    const buildUrl = (path? :string) :string | undefined => {
        if (!path || !token || !wsUrl) return undefined
        const url = UtilParam.url(wsUrl, path)
        url.setAllParams([{type: 'token', values: [token]}])
        return url.toString()
    }

    const url :string | undefined = buildUrl(path)

    return { url, buildUrl }
}

export default HookWebSocketUrl