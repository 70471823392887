import { ComponentEuiCheckBox } from 'Component'
import React from 'react'

type Props = {
    currentCount : number
    allCount :number
    onChange :(action :'none' | 'all') => void
}

const ModuleTableColumnControlSelectHeader = ({ currentCount, allCount, onChange } :Props) => {
    const checked :boolean = currentCount > 0
    const indeterminate :boolean = checked && currentCount < allCount
    return <ComponentEuiCheckBox id={'header'}
                                 checked={checked}
                                 indeterminate={indeterminate}
                                 onChange={() => {
                                     if (currentCount) {
                                         onChange('none')
                                     } else {
                                         onChange('all')
                                     }
                                 }}/>
}

export default ModuleTableColumnControlSelectHeader