import {ModuleTableColumnControlSelect, ModuleTableColumnControlSelectHeader} from "Module";
import React, {FunctionComponent, ReactNode, useState} from "react";
import {
    TypeEntityRow,
    TypeEuiPropsDataGridCellValueElement,
    TypeEuiPropsDataGridControlColumn,
    TypeModuleTableColumnControlSelect
} from 'Type'

type Props = {
    data? :TypeEntityRow[]
}


const HookModuleTableDataRowSelect = ({ data } :Props) :TypeModuleTableColumnControlSelect => {

    const [selected, setSelected] = useState<Set<number>>(new Set())

    const selectSingle = (row :TypeEntityRow) => {
        const updated :Set<number> = new Set(selected)
        if (selected.has(row.rowId)) {
            updated.delete(row.rowId)
        } else {
            updated.add(row.rowId)
        }
        setSelected(updated)
    }

    const onClickHeader = (action :'all' | 'none') => {
        if (action === 'none') {
            setSelected(new Set())
        } else {
            if (data) setSelected(new Set(data.map((row :TypeEntityRow) => row.rowId)))
        }
    }

    const clearSelected = () => {
        setSelected(new Set())
    }

    const headerCellRender :FunctionComponent = () => (data) ? <div style={{marginRight: '6px', float: 'right'}}>
          <ModuleTableColumnControlSelectHeader currentCount={selected.size} allCount={data.length} onChange={onClickHeader}/>
        </div> : null

    const selectControl :TypeEuiPropsDataGridControlColumn = {
        id: 'select',
        width: 30,
        headerCellRender: headerCellRender,
        rowCellRender: (props :TypeEuiPropsDataGridCellValueElement) :ReactNode => {
            if (!data) return null
            const row :TypeEntityRow = data[props.rowIndex]
            return data && <div style={{marginRight: '6px', float: 'right'}}>
              <ModuleTableColumnControlSelect row={row} checked={selected.has(row.rowId)} onChange={selectSingle}/>
            </div>
        }
    }

    const selectedRows = data ? data.filter((row :TypeEntityRow) => selected.has(row.rowId)) : []

    return {
        selectControl,
        selectedRows,
        clearSelected
    }
}

export default HookModuleTableDataRowSelect