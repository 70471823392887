import {HookNavResourceNav, HookPageParamSimpleInteger} from 'Hook'
import {
    TypeControlParamSimple,
    TypeEntityTransformHeader,
    TypeEntityTransformHeaderError,
    TypeModuleTransformHeaderErrorControl,
    TypeModuleTransformHeaderErrorMap,
    TypeNav,
    TypeNavHttpControl,
    TypeUrlParam
} from 'Type'

type Props = {
    headers? :TypeEntityTransformHeader[]
    transformNav? :TypeNav<any>
}

const HookModuleTransformHeaderErrorMap = ({headers, transformNav} :Props) :TypeModuleTransformHeaderErrorControl => {

    const [size] :TypeControlParamSimple<number> = HookPageParamSimpleInteger('size')
    const [page] :TypeControlParamSimple<number> = HookPageParamSimpleInteger('page')

    const paramUpdates :TypeUrlParam[] = [
        {type: 'size', values: [`${size}`]},
        {type: 'page', values: [`${page}`]}
    ]

    const loadErrors :boolean = size !== undefined && page !== undefined

    const errorControl :TypeNavHttpControl<TypeEntityTransformHeaderError> = HookNavResourceNav<TypeEntityTransformHeaderError>({ key: 'error', nav: (loadErrors) ? transformNav : undefined, paramUpdates })
    const errors :TypeEntityTransformHeaderError[] = errorControl.res?.data?.data.page?.content ?? []

    const slugToCamel :{[slug :string] :string} = {}
    const map :TypeModuleTransformHeaderErrorMap = {}
    headers?.forEach((header :TypeEntityTransformHeader) => {
        slugToCamel[header.destinationColumnNameSlug] = header.destinationColumnNameCamel
        map[header.destinationColumnNameCamel] = []
    })

    errors.forEach((error :TypeEntityTransformHeaderError) => map[slugToCamel[error.destinationColumnNameSlug]].push(error))

    return {
        errors: map,
        refresh: async () => {
            await errorControl.submit()
        },
    }
}

export default HookModuleTransformHeaderErrorMap