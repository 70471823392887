import { HookPageParamSimple } from 'Hook'
import { TypeControlParamSimple, TypeUrlParamType } from 'Type'

const HookPageParamSimpleInteger = (type :TypeUrlParamType, defaultVal? :number) :TypeControlParamSimple<number> => {

    const defaultValStr :string | undefined = (defaultVal !== undefined) ? `${defaultVal}` : undefined

    const [value, setValue] :TypeControlParamSimple<string> = HookPageParamSimple(type, (val :string) => !isNaN(parseInt(val)), defaultValStr)

    const setValueNumber = (value :number) => {
        setValue(`${value}`)
    }

    const valueNumber :number | undefined = (value) ? parseInt(value) : undefined

    return [valueNumber, setValueNumber]
}

export default HookPageParamSimpleInteger