import React, {PropsWithChildren, ReactNode} from 'react'
import {
    ComponentDivMarginHorizontal,
    ComponentEuiFlexGroup,
    ComponentEuiFlexItem,
    ComponentEuiPageSection,
    ComponentEuiSpacer,
    ComponentEuiText,
    ComponentPageTitle
} from 'Component'
import { TypeNavMeta } from 'Type'

type Props = {
    title? :string
    meta? :TypeNavMeta
    bottomBorder? :boolean | 'extended'
    rightActions? :ReactNode
    compact? :boolean
}

const ComponentNavMetaPageSectionHeader = ({ title, meta, bottomBorder, rightActions, compact } :PropsWithChildren<Props>) => {

    const description :string[] = (meta && meta?.description && Array.isArray(meta?.description)) ? meta?.description : (meta?.description) ? [meta.description as string] : []

    return <ComponentEuiPageSection paddingSize={(compact) ? 'xs' : 'm'} bottomBorder={bottomBorder}>
        <ComponentDivMarginHorizontal margin={4}>
            <ComponentEuiFlexGroup gutterSize={'none'} alignItems={'center'}>
                <ComponentEuiFlexItem grow>
                    <ComponentPageTitle secondary={!!(compact)}>{ title || meta?.title }</ComponentPageTitle>
                    <ComponentEuiSpacer size={'s'}/>
                    {description.map((text :string, index :number) => <span key={`${index}`}>
                        <ComponentEuiText>{text}</ComponentEuiText>
                        <ComponentEuiSpacer size={'xs'}/>
                    </span>)}
                </ComponentEuiFlexItem>
                <ComponentEuiFlexItem>{ rightActions }</ComponentEuiFlexItem>
            </ComponentEuiFlexGroup>
        </ComponentDivMarginHorizontal>
    </ComponentEuiPageSection>
}

export default ComponentNavMetaPageSectionHeader