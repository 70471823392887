import { ComponentEuiCheckBox } from 'Component'
import { TypeEntityRow } from 'Type'

type Props = {
    row :TypeEntityRow
    checked :boolean
    onChange :(row :TypeEntityRow) => void
}

const ModuleTableColumnControlSelect = ({ row, checked, onChange } :Props) => {
    return <ComponentEuiCheckBox id={row.linkTo} checked={checked} onChange={() => {onChange(row)}}/>
}

export default ModuleTableColumnControlSelect;