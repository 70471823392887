import {EuiDataGrid} from '@elastic/eui'
import { TypeEuiPropsDataGrid } from 'Type'


const ComponentEuiDataGrid = ({height, width, columns, leadingControlColumns, trailingControlColumns, columnVisibility,
                                  schemaDetectors, rowCount, renderCellValue, renderCellPopover, gridStyle,
                                  inMemory, pagination, sorting, minSizeForControls, forwardRef, toolbarVisibility} :TypeEuiPropsDataGrid) => {

    return <EuiDataGrid aria-labelledby={'data-grid'} height={height} width={width} columns={columns}
                 leadingControlColumns={leadingControlColumns}
                 trailingControlColumns={trailingControlColumns} columnVisibility={columnVisibility}
                 schemaDetectors={schemaDetectors}
                 rowCount={rowCount} renderCellValue={renderCellValue} renderCellPopover={renderCellPopover}
                 gridStyle={gridStyle}
                 inMemory={inMemory} pagination={pagination} sorting={sorting}
                 minSizeForControls={minSizeForControls} ref={forwardRef} toolbarVisibility={toolbarVisibility}/>
}
export default ComponentEuiDataGrid