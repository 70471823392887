import { ContextTableData } from 'Context'
import {HookControlSwitch, HookModuleTableDataContextColor, HookNavPaginatePage } from 'Hook'
import {PropsWithChildren, useEffect} from 'react'
import {TypeContextTableData, TypeEntityRow, TypeEntityTableVersionColumn,
    TypeModuleTableDataContextColorControl, TypeNav, TypeNavDataPage, TypeNavHttpControl,
    TypeNavSocketPayload, TypeWebSocketControl, TypeNavSocketPayloadType, TypeControlSwitch } from 'Type'
import {FORMAT_TABLE_DATA_SYSTEM_COLUMNS, FORMAT_TABLE_COLOR_FLASH_MS} from 'Format'

type Props = {
    dataControl :TypeNavHttpControl<TypeEntityRow>
    columnControl :TypeNavHttpControl<TypeEntityTableVersionColumn>
    updatesControl :TypeWebSocketControl<TypeNavSocketPayload<number>>
}

const ModuleTableDataContext = ({ dataControl, columnControl, updatesControl, children } :PropsWithChildren<Props>) => {

    const refreshSwitch :TypeControlSwitch = HookControlSwitch()

    const dataNav :TypeNav<TypeEntityRow> | undefined = dataControl.res?.data
    const { size, page } = HookNavPaginatePage({ nav:dataNav })
    const dataPage :TypeNavDataPage<TypeEntityRow> | undefined = dataNav?.data.page
    const data :TypeEntityRow[] = (dataPage) ? dataPage.content : (dataNav?.data.entity) ? [dataNav?.data.entity] : []

    useEffect(() => {
        setTimeout(() => refreshSwitch.toggle(), FORMAT_TABLE_COLOR_FLASH_MS + 2)
    }, [updatesControl?.messages.length])

    const colorControl :TypeModuleTableDataContextColorControl = HookModuleTableDataContextColor()
    const colors :{[id :string] :string} = colorControl.map

    const value :TypeContextTableData = {
        columns: {},
        updates: {},
        data :[...data],
        colors
    }

    const addColumnToContextData = (column :TypeEntityTableVersionColumn) => {
        value.columns[column.nameCamel] = column
    }
    const isUpdateOfTypeFn = (payloadType :TypeNavSocketPayloadType[]) => (update :TypeNavSocketPayload<number>) :boolean => payloadType.includes(update.type)
    const addDeleteDataToContextData = (del :TypeNavSocketPayload<number>) => {
        value.data = value.data.filter((row :TypeEntityRow) => row.rowId !== del.id)
    }

    const addCreateDataToContextData = (create :TypeNavSocketPayload<number>) => {
        if (page === 0 && data.filter((data :any) => data['rowId'] === create.id).length === 0) {
            const length :number = value.data.unshift(create.data)
            if (size && length > size) value.data.pop()
        }
    }

    const addUpdateDataToContextData = (update :TypeNavSocketPayload<number>) => {
        if (!value.updates.hasOwnProperty(update.id)) value.updates[update.id] = []
        colorControl.addColor(`${update.data[FORMAT_TABLE_DATA_SYSTEM_COLUMNS.updatedBy]}`)
        value.updates[update.id].push(update)
    }

    (columnControl.res?.data?.data.page?.content ?? []).forEach(addColumnToContextData)

    updatesControl.messages.filter(isUpdateOfTypeFn(['delete'])).forEach(addDeleteDataToContextData)
    updatesControl.messages.filter(isUpdateOfTypeFn(['create'])).forEach(addCreateDataToContextData)
    updatesControl.messages.filter(isUpdateOfTypeFn(['create', 'update'])).forEach(addUpdateDataToContextData)

    return <ContextTableData.Provider value={value}>
        { children }
    </ContextTableData.Provider>

}

export default ModuleTableDataContext