import { useLocation, useNavigate } from 'react-router-dom'
import { TypeControlPageLocation } from 'Type'

const HookPageLocation = (removeFirst? :string) :TypeControlPageLocation => {
    const { pathname, search } = useLocation()
    const location = `${ (removeFirst) ? pathname.replace(removeFirst, '') : pathname }${ search }`
    const navigate = useNavigate()
    const setLocation = (location :string) => navigate(location)
    return { location, setLocation }
}

export default HookPageLocation