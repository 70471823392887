import { HookNavParamSimpleInteger } from 'Hook'
import { useNavigate } from 'react-router-dom'
import { TypeNav, TypeControlPaginate, TypeControlParamSimple } from 'Type'

const defaultPageValue :boolean = true

type Props = {
    nav? :TypeNav<any>
    prependPath? :string
}

const HookNavPaginatePage = ({ nav, prependPath } :Props) :TypeControlPaginate => {

    const navigate = useNavigate()

    const onChange = (path :string) => navigate((prependPath) ? `${prependPath}${path}` : path)

    const [page, setPage] :TypeControlParamSimple<number> = HookNavParamSimpleInteger({nav, type: 'page', onChange, defaultPageValue})
    const [size, setSize] :TypeControlParamSimple<number> = HookNavParamSimpleInteger({nav, type: 'size', onChange, defaultPageValue})


    const pageCount :number | undefined = nav?.data.page?.totalPages

    return {page, size, setPage, setSize, pageCount}
}

export default HookNavPaginatePage