import {ComponentEuiFlexGroup, ComponentEuiFlexItem, ComponentEuiIcon} from 'Component'
import { TypeEuiPropsIconType } from 'Type'


type Props = {
    title :string
    iconType :TypeEuiPropsIconType
    entityName :string
}

const ComponentNavSidebarTitle = ({ title, iconType, entityName } :Props) =>
    <>
        <div style={{backgroundColor: '#25282f', borderRadius: '6px', padding: '12px', color: 'white'}}>
            <ComponentEuiFlexGroup alignItems={'baseline'} gutterSize={'s'}>
                <ComponentEuiFlexItem grow><span style={{color: '#FFF', fontWeight: 500}}>{title}</span></ComponentEuiFlexItem>
                <ComponentEuiFlexItem><span style={{}}><ComponentEuiIcon type={iconType}/></span></ComponentEuiFlexItem>
            </ComponentEuiFlexGroup>
        </div>
    </>

export default ComponentNavSidebarTitle