import { ComponentEuiButtonEmpty } from 'Component'
import { GlobalSystemColumn } from 'Global'
import { TypeControlSwitch } from 'Type'

const ModuleTableColumnControlSystemToggle = () => {
    const control :TypeControlSwitch = GlobalSystemColumn()
    return <ComponentEuiButtonEmpty size={'xs'} iconType={'indexSettings'} onClick={control.toggle} color={'text'}>
        { (control.value) ? 'Hide system columns' : 'Show system columns' }
    </ComponentEuiButtonEmpty>
}

export default ModuleTableColumnControlSystemToggle