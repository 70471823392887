import { FORMAT_PAGE_HEADER_SINGLE_HEIGHT } from 'Format'
import { TypeNavHttpControl, TypePageHeaderHeight } from 'Type'

type Props = {
    control? :TypeNavHttpControl<any>
    hasBreadcrumbs? :boolean
}

const HookPageHeaderHeight = ({ control, hasBreadcrumbs} :Props) :TypePageHeaderHeight => {
    const hasBreadcrumbsInner :boolean = Boolean(control && hasBreadcrumbs)
    const headerHeight :number = FORMAT_PAGE_HEADER_SINGLE_HEIGHT * ((hasBreadcrumbs) ? 2 : 1)
    return { hasBreadcrumbs: hasBreadcrumbsInner, headerHeight }
}

export default HookPageHeaderHeight