import { ComponentEuiHeaderLink, ComponentEuiHeaderSectionItem } from 'Component'
import {useLocation, useNavigate} from 'react-router-dom'
import { FORMAT_PAGE_URLS } from 'Format'

const ModulePageHeaderItemNavigator = () => {

    const location = useLocation()
    const navigate = useNavigate()

    const isNav :boolean = location.pathname.startsWith(FORMAT_PAGE_URLS.nav)

    const onClick = () => {
        navigate((isNav) ? location.pathname.replace(FORMAT_PAGE_URLS.nav, '') : `${FORMAT_PAGE_URLS.nav}${location.pathname}`)
    }

    return <ComponentEuiHeaderSectionItem>
        <ComponentEuiHeaderLink key={`${isNav}`} isActive={isNav} onClick={onClick}>{(isNav) ? 'Exit API Navigator' : 'API Navigator'}</ComponentEuiHeaderLink>
    </ComponentEuiHeaderSectionItem>
}

export default ModulePageHeaderItemNavigator