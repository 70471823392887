
import {UtilString} from 'Util'
import {
    ComponentEuiButton, ComponentEuiCallout,
    ComponentEuiModal,
    ComponentEuiModalBody, ComponentEuiModalFooter,
    ComponentEuiModalHeader,
    ComponentEuiModalHeaderTitle, ComponentNavFormButtonCancel
} from 'Component'
import {HookControlSwitch, HookHttpDeleteMulti} from 'Hook'
import {TypeControlSwitch, TypeHttpControlDeleteMulti, TypeHttpResponse, TypeNav} from 'Type'

type Props = {
    paths :string[]
    onClose: () => void
    onComplete: () => void
    entityName :string
    entityNamePlural? :string
}

const ComponentNavActionModalDeleteMulti = ({ paths, onClose, entityName, entityNamePlural, onComplete } :Props) => {

    const loadingSwitch :TypeControlSwitch = HookControlSwitch()
    const control :TypeHttpControlDeleteMulti<TypeNav<any>> = HookHttpDeleteMulti({ paths })

    const onClick = async () => {
        loadingSwitch.turnOn()
        await control.deleteAll()
        loadingSwitch.turnOff()
        onComplete()
    }

    const errors :string[] = Object.values(control.resMap)
        .map((res :TypeHttpResponse<TypeNav<any>>) =>
            (res.error) ? res.error : (res.data?.error?.message) ? res.data?.error?.message : ''
        ).filter((error :string) => (error !== ''))

    const plural :string = entityNamePlural || `${entityName}s`
    const confirmMessage :string = `Are you sure you would like to delete ${paths.length} ${plural}`
    const loadingMessage :string = `${Object.keys(control.resMap).length} out of ${paths.length} deleted`

    return <ComponentEuiModal onClose={onClose}>
        <ComponentEuiModalHeader>
            <ComponentEuiModalHeaderTitle>{UtilString.capitalize(entityName)}</ComponentEuiModalHeaderTitle>
        </ComponentEuiModalHeader>
        <ComponentEuiModalBody>
            {(loadingSwitch.value) ? <ComponentEuiCallout color={'primary'} iconType={'check'} title={loadingMessage}/> :
                <ComponentEuiCallout color={'warning'} iconType={'alert'} title={confirmMessage}/> }
            {(errors).map((error :string) => <ComponentEuiCallout color={'danger'} iconType={'alert'} title={error}/>)}
        </ComponentEuiModalBody>
        <ComponentEuiModalFooter>
            <ComponentNavFormButtonCancel onClick={onClose}/>
            <ComponentEuiButton onClick={onClick} isLoading={loadingSwitch.value} isDisabled={paths.length === 0} color={'danger'}>{'Delete'}</ComponentEuiButton>
        </ComponentEuiModalFooter>
    </ComponentEuiModal>
}

export default ComponentNavActionModalDeleteMulti