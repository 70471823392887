import { HookPageParamSimple } from 'Hook'
import { TypeControlParamSimple, TypeUrlParamType } from 'Type'

const HookPageParamSimpleBoolean = (type :TypeUrlParamType, defaultVal? :boolean) :TypeControlParamSimple<boolean> => {

    const defaultValStr :string | undefined = (defaultVal !== undefined) ? `${defaultVal}` : undefined

    const isValid = (val :string) :boolean => {
        const valLowerCase = val.toLowerCase()
        return (valLowerCase === 'true' || valLowerCase === 'false')
    }

    const [value, setValue] :TypeControlParamSimple<string> = HookPageParamSimple(type, isValid, defaultValStr)

    const setValueNumber = (value :boolean) => {
        setValue(`${value}`)
    }

    const valueBool :boolean | undefined = (value) ? value.toLowerCase() === 'true' : undefined

    return [valueBool, setValueNumber]
}

export default HookPageParamSimpleBoolean