import { HookNavPage } from 'Hook'
import { TypeNavHttpControl, TypeEntityLinkTo, TypeHttpLoadable } from 'Type'
import { ModulePage } from 'Module'
import { PageNavigatorBody } from 'Page'
import { FORMAT_PAGE_URLS } from 'Format'

const PageNavigator = () => {

    const pageControl :TypeNavHttpControl<TypeEntityLinkTo> = HookNavPage<any>(FORMAT_PAGE_URLS.nav)

    const toLoad :TypeHttpLoadable[] = [
        {
            label: 'Page',
            control: pageControl,
        }
    ]

    const body = <PageNavigatorBody pageControl={pageControl}/>

    return <ModulePage control={pageControl} sidebarPaddingSize={'m'} toLoad={toLoad} paginationControl={pageControl} hasBreadcrumbs={false} prependPath={FORMAT_PAGE_URLS.nav}>{body}</ModulePage>

}

export default PageNavigator