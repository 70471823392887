import { ClassPublicEnv } from 'Class'
import { useEffect, useState } from 'react'
import { TypePublicEnv } from 'Type'

const HookPublicEnv = () :TypePublicEnv | undefined => {
    const [env, setEnv] = useState<TypePublicEnv | undefined>()

    useEffect(() => { ClassPublicEnv.getPromise().then(setEnv) },[])

    return env
}


export default HookPublicEnv